<!-- Content -->
<div class="page-content">
  <!-- Slider -->
  <div id="home" class="main-slider style-two default-banner" style="background-color: rgba(0, 0, 0, 0.5)">
    <div class="tp-banner-container">
      <div class="tp-banner">
        <div id="rev_slider_486_1_wrapper" class="rev_slider_wrapper fullwidthbanner-container"
          data-alias="news-gallery36" data-source="gallery" style="
            margin: 0px auto;
            background-color: #ffffff;
            padding: 0px;
            margin-top: 0px;
            margin-bottom: 0px;
          ">
          <!-- START REVOLUTION SLIDER 5.3.0.2 fullwidth mode -->
          <div id="slider_01" class="rev_slider fullwidthabanner slider" style="display: none" data-version="5.3.0.2">
            <ul>
              <!-- SLIDE -->
              <li data-index="rs-200" data-transition="parallaxvertical" data-slotamount="default"
              data-hideafterloop="0" data-hideslideonmobile="off" data-easein="default" data-easeout="default"
              data-masterspeed="default" data-thumb="assets/images/main-slider/slide2.jpg" data-rotate="0"
              data-fstransition="fade" data-fsmasterspeed="1500" data-fsslotamount="7" data-saveperformance="off"
              data-title="" data-param1="" data-param2="" data-param3="" data-param4="" data-param5="" data-param6=""
              data-param7="" data-param8="" data-param9="" data-param10="">
              <!-- MAIN IMAGE -->
              <img src="assets/images/58.jpg" style="
                  width: 100%;
                  height: auto;
                  position: relative;
                  display: block;
                  background: linear-gradient(
                      rgba(0, 0, 0, 0.5),
                      rgba(0, 0, 0, 0.5)
                    ),
                    url('assets/images/58.jpg');
                  background-size: cover;
                  background-position: center;
                " alt="" data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat"
                data-bgparallax="10" class="rev-slidebg" data-no-retina />
              <!-- LAYERS -->

              <!-- LAYER NR. 1 -->
              <div class="tp-caption Newspaper-Subtitle text-primary tp-resizeme" id="slide-200-layer-1"
                data-x="['left','left','left','left']" data-hoffset="['50','50','50','30']"
                data-y="['top','top','top','top']" data-voffset="['100','300','300','300']"
                data-fontsize="['40','35','20','20']" data-lineheight="['45','40','25','25']"
                data-width="['800','800','800','420']" data-height="none" data-whitespace="nowrap" data-type="text"
                data-responsive_offset="on"
                data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'
                data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="
                  z-index: 7;
                  white-space: nowrap;
                  text-transform: left;
                  font-family: Oswald;
                  font-weight: 600;
                ">
                EAST & WEST FINALS
              </div>

              <!-- LAYER NR. 2 -->
              <div class="tp-caption Newspaper-Title tp-resizeme" id="slide-200-layer-2"
                data-x="['left','left','left','left']" data-hoffset="['50','50','50','30']"
                data-y="['top','top','top','top']" data-voffset="['150','350','330','330']"
                data-fontsize="['80','80','50','30']" data-lineheight="['85','85','55','35']"
                data-width="['1000','1000','1000','420']" data-height="none" data-whitespace="normal" data-type="text"
                data-responsive_offset="on"
                data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'
                data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]" data-paddingbottom="[10,10,10,10]" data-paddingleft="[0,0,0,0]" style="
                  z-index: 6;
                  white-space: normal;
                  text-transform: left;
                  font-weight: 600;
                  line-height: 80px;
                  font-family: Oswald;
                  color: #fff;
                ">
                SAKAJA SUPA CUP <span class="text-primary">2024</span>
              </div>

              <!-- LAYER NR. 3 -->
              <div class="tp-caption Newspaper-Title tp-resizeme" id="slide-100-layer-3"
                data-x="['left','left','left','left']" data-hoffset="['50','50','50','30']"
                data-y="['top','top','top','top']" data-voffset="['250','450','400','380']"
                data-fontsize="['28','24','20','18']" data-lineheight="['38','34','30','28']"
                data-width="['800','800','600','400']" data-height="none" data-whitespace="normal" data-type="text"
                data-responsive_offset="on"
                data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'
                data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]" data-paddingbottom="[10,10,10,10]" data-paddingleft="[0,0,0,0]" style="
                  z-index: 6;
                  white-space: normal;
                  text-transform: left;
                  line-height: 100px;
                  color: #fff;
                  font-family: roboto;
                  font-weight: bold;
                ">
               Githurai All Stars
                <br>
                <strong>VS</strong>
                <br>
                Shauri Moyo Utd
                <br><br>
                📅 Date: 18th December 2024
                <br>
                ⚽ Don't Miss the Action!
              </div>


              <!-- LAYER NR. 4 -->
              <div class="tp-caption" id="slide-200-layer-4" data-x="['left','left','left','left']"
                data-hoffset="['53','53','50','30']" data-y="['top','top','top','top']"
                data-voffset="['350','550','500','510']" data-width="none" data-height="none" data-whitespace="nowrap"
                data-responsive_offset="on" data-responsive="off"
                data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;","ease":"Power3.easeInOut"},{"frame":"hover","speed":"300","ease":"Power1.easeInOut","to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgba(0, 0, 0, 1.00);bg:rgba(255, 255, 255, 1.00);bc:rgba(255, 255, 255, 1.00);bw:1px 1px 1px 1px;"}]'
                data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="
                  z-index: 8;
                  white-space: nowrap;
                  background-color: rgba(0, 0, 0, 0) !important;
                "></div>

              <div class="tp-caption" id="slide-200-layer-5" data-x="['left','left','left','left']"
                data-hoffset="['200','200','200','180']" data-y="['top','top','top','top']"
                data-voffset="['350','550','500','510']" data-width="none" data-height="none" data-whitespace="nowrap"
                data-responsive_offset="on" data-responsive="off"
                data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;","ease":"Power3.easeInOut"},{"frame":"hover","speed":"300","ease":"Power1.easeInOut","to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgba(0, 0, 0, 1.00);bg:rgba(255, 255, 255, 1.00);bc:rgba(255, 255, 255, 1.00);bw:1px 1px 1px 1px;"}]'
                data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="
                  z-index: 8;
                  white-space: nowrap;
                  background-color: rgba(0, 0, 0, 0) !important;
                "></div>
            </li>
              <li data-index="rs-100" data-transition="parallaxvertical" data-slotamount="default"
                data-hideafterloop="0" data-hideslideonmobile="off" data-easein="default" data-easeout="default"
                data-masterspeed="default" data-thumb="assets/images/main-slider/slide1.jpg" data-rotate="0"
                data-fstransition="fade" data-fsmasterspeed="1500" data-fsslotamount="7" data-saveperformance="off"
                data-title="" data-param1="" data-param2="" data-param3="" data-param4="" data-param5="" data-param6=""
                data-param7="" data-param8="" data-param9="" data-param10="">
                <!-- MAIN IMAGE -->
                <img src="assets/images/67.jpg" style="
                    width: 100%;
                    height: auto;
                    position: relative;
                    display: block;
                    background: linear-gradient(
                        rgba(0, 0, 0, 0.5),
                        rgba(0, 0, 0, 0.5)
                      ),
                      url('assets/images/67.jpg');
                    background-size: cover;
                    background-position: center;
                  " alt="" data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat"
                  data-bgparallax="10" class="rev-slidebg" data-no-retina />
                <!-- LAYERS -->

                <!-- LAYER NR. 1 -->
                <!-- <div class="tp-caption Newspaper-Subtitle text-primary tp-resizeme" id="slide-100-layer-1"
                  data-x="['left','left','left','left']" data-hoffset="['50','50','50','30']"
                  data-y="['top','top','top','top']" data-voffset="['100','300','300','300']"
                  data-fontsize="['40','35','20','20']" data-lineheight="['45','40','25','25']"
                  data-width="['800','800','600','400']" data-height="none" data-whitespace="nowrap" data-type="text"
                  data-responsive_offset="on"
                  data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'
                  data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="
                    z-index: 7;
                    white-space: nowrap;
                    text-transform: left;
                    font-family: Oswald;
                    font-weight: 600;
                  ">
                  EAST & WEST FINALS
                </div> -->

                <!-- LAYER NR. 2 -->
                <!-- <div class="tp-caption Newspaper-Title tp-resizeme" id="slide-100-layer-2"
                  data-x="['left','left','left','left']" data-hoffset="['50','50','50','30']"
                  data-y="['top','top','top','top']" data-voffset="['150','350','330','330']"
                  data-fontsize="['80','80','50','30']" data-lineheight="['85','85','55','35']"
                  data-width="['800','800','600','400']" data-height="none" data-whitespace="normal" data-type="text"
                  data-responsive_offset="on"
                  data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'
                  data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]" data-paddingbottom="[10,10,10,10]" data-paddingleft="[0,0,0,0]" style="
                    z-index: 6;
                    white-space: normal;
                    text-transform: left;
                    font-weight: 600;
                    line-height: 80px;
                    font-family: Oswald;
                    color: #fff;
                  ">
                  SAKAJA SUPA CUP <span class="text-primary">2024</span>
                </div> -->

                <!-- LAYER NR. 3 -->
                <!-- <div class="tp-caption Newspaper-Title tp-resizeme" id="slide-100-layer-3"
                  data-x="['left','left','left','left']" data-hoffset="['50','50','50','30']"
                  data-y="['top','top','top','top']" data-voffset="['250','450','400','380']"
                  data-fontsize="['28','24','20','18']" data-lineheight="['38','34','30','28']"
                  data-width="['800','800','600','400']" data-height="none" data-whitespace="normal" data-type="text"
                  data-responsive_offset="on"
                  data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'
                  data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]" data-paddingbottom="[10,10,10,10]" data-paddingleft="[0,0,0,0]" style="
                    z-index: 6;
                    white-space: normal;
                    text-transform: left;
                    line-height: 100px;
                    color: #fff;
                    font-family: roboto;
                    font-weight: bold;
                  ">
                  Kawasaki FC
                  <br>
                  <strong>VS</strong>
                  <br>
                  South C Rangers
                  <br><br>
                  📅 Date: 18th December 2024
                  <br>
                  ⚽ Don't Miss the Action!
                </div> -->


                <!-- LAYER NR. 4 -->
                <div class="tp-caption" id="slide-100-layer-4" data-x="['left','left','left','left']"
                  data-hoffset="['53','53','50','30']" data-y="['top','top','top','top']"
                  data-voffset="['350','550','500','510']" data-width="none" data-height="none" data-whitespace="nowrap"
                  data-responsive_offset="on" data-responsive="off"
                  data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;","ease":"Power3.easeInOut"},{"frame":"hover","speed":"300","ease":"Power1.easeInOut","to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgba(0, 0, 0, 1.00);bg:rgba(255, 255, 255, 1.00);bc:rgba(255, 255, 255, 1.00);bw:1px 1px 1px 1px;"}]'
                  data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="
                    z-index: 8;
                    white-space: nowrap;
                    background-color: rgba(0, 0, 0, 0) !important;
                  "></div>

                <div class="tp-caption" id="slide-100-layer-5" data-x="['left','left','left','left']"
                  data-hoffset="['200','200','200','180']" data-y="['top','top','top','top']"
                  data-voffset="['350','550','500','510']" data-width="none" data-height="none" data-whitespace="nowrap"
                  data-responsive_offset="on" data-responsive="off"
                  data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;","ease":"Power3.easeInOut"},{"frame":"hover","speed":"300","ease":"Power1.easeInOut","to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgba(0, 0, 0, 1.00);bg:rgba(255, 255, 255, 1.00);bc:rgba(255, 255, 255, 1.00);bw:1px 1px 1px 1px;"}]'
                  data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="
                    z-index: 8;
                    white-space: nowrap;
                    background-color: rgba(0, 0, 0, 0) !important;
                  "></div>
              </li>
              <!-- SLIDE -->
              <!-- SLIDE 2 -->

              <!-- SLIDE 2 -->
            </ul>
            <div class="tp-bannertimer tp-bottom bg-primary"></div>
          </div>
        </div>
        <!-- END REVOLUTION SLIDER -->
      </div>
    </div>
  </div>
  <!-- Slider END -->
  <div id="sectionFixtures" class="section-full bg-white bg-img-fix overlay-white-dark" style="
      background-image: url(assets/images/background/bg5.jpg);
      background-position: center;
      padding-top: 10vh;
      padding-bottom: 2vh;
    ">
    <div class="container">
      <div class="section-content">
        <div class="row">
          <div class="col-lg-12 text-center section-head">
            <h2 class="h2 text-uppercase">Fixtures</h2>
            <div class="dez-separator-outer">
              <div class="dez-separator bg-primary style-liner"></div>
            </div>
            <div class="clear"></div>
            <p class="m-b0">
              Exciting matches will light up venues across the city. <br />
              Don’t miss out on the action! ⚽️
            </p>
          </div>
        </div>
      </div>
      <app-fixtures></app-fixtures>
    </div>
  </div>
  <!-- Latest Result -->
  <div class="section-full score-board bg-white content-inner-2">
    <div class="container">
      <div class="section-content">
        <div class="row">
          <div class="col-lg-12 text-center section-head">
            <h2 class="h2 text-uppercase">Quarter Finals</h2>
            <div class="dez-separator-outer">
              <div class="dez-separator bg-primary style-liner"></div>
            </div>
            <div class="clear"></div>
            <p class="m-b0">
              Exciting matches will light up venues across the city. <br />
              Don’t miss out on the action! ⚽️
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-5 col-md-5">
            <div class="team-box-left clearfix">
              <div class="player pull-right">
                <img width="192" height="351" src="assets/images/sakajafix1.png" alt="" />
              </div>
              <div class="team-result text-white text-left">
                <h4 class="text-uppercase font-weight-600 m-a0">Kawasaki FC</h4>
                <!-- <div class="m-tb5"><span class="text-green">WIN</span></div> -->
                <a href="javascript:void(0);" class="site-button">Score Board</a>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-2 text-center p-lr0">
            <div class="score-info">
              <span class="vs">V/S</span>
              <span class="score" style="font-size: 70px; margin-top: 5px;">1:00pm</span>

              <p style="margin-top: 20px;">DEC 18,2024 Dandora Stadium</p>
            </div>
          </div>
          <div class="col-lg-5 col-md-5">
            <div class="team-box-right clearfix">
              <div class="player pull-left">
                <img width="192" height="351" src="assets/images/sakajafix2.png" alt="" />
              </div>
              <div class="team-result text-white text-right">
                <h4 class="text-uppercase font-weight-600 m-a0">South C Rangers</h4>
                <!-- <div class="m-tb5"><span class="text-red">LOSS</span></div> -->
                <a href="javascript:void(0);" class="site-button">Score Board</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Latest Result END -->
  <!-- Next Match -->
  <section id="next-match">
    <div class="section-full bg-white content-inner-1 bg-img-fix overlay-white-dark" style="
        background-image: url(assets/images/background/bg5.jpg);
        background-position: center;
      ">
      <div class="container">
        <div class="section-head text-center">
          <h2 class="h2 text-uppercase">Next Match</h2>
          <div class="dez-separator-outer">
            <div class="dez-separator bg-primary style-liner"></div>
          </div>
          <p>
            The unique approach of the Sakaja Super Cup, with its focus on
            emerging talent, substantial rewards, and strong organizational
            backing, ensure its place as one of the most notable competitions in
            Nairobi.
          </p>
        </div>
        <div class="section-content">
          <div class="next-match owl-loaded owl-theme owl-dots-none owl-carousel owl-btn-center-lr">
            @for (match of randomUpcomingMatches; track $index) {
            <div class="item p-t20">
              <div class="row next-match-team clearfix m-b30">
                <div class="col-lg-5 col-sm-5 col-4">
                  <a href="javascript:void(0);" class="pull-right m-t20">
                    <span class="country"><img width="50" height="50" src="assets/images/sakajalogo2.jpg"
                        alt="" /></span>
                    <span>Kawasaki FC</span>
                    <!-- <span>{{ match?.teamA }}</span> -->
                  </a>
                </div>
                <div class="col-lg-2 col-sm-2 col-4">
                  <div class="vs-team bg-primary"><span>VS</span></div>
                </div>
                <div class="col-lg-5 col-sm-5 col-4">
                  <a href="javascript:void(0);" class="pull-left m-t20">
                    <span>South C Rangers</span>
                    <!-- <span>{{ match?.teamB }}</span> -->
                    <span class="country"><img width="50" height="50" src="assets/images/sakajalogo2.jpg"
                        alt="" /></span>
                  </a>
                </div>
              </div>

              <div class="m-t30 loction text-center">
                <!-- <strong>{{ match?.date | date : "yyyy-MM-dd" }}</strong>
                <strong>{{ match?.location }}</strong> -->
                <strong>18 DEC 2024</strong>
                <strong>Dandora Stadium</strong>
              </div>
            </div>
            }
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Next Match End -->
  <!-- About The Sports -->
  <div id="sectionAbout" class="section-full bg-white content-inner">
    <div class="container">
      <div class="section-head text-center">
        <h2 class="h2 text-uppercase">About The Sports</h2>
        <div class="dez-separator-outer">
          <div class="dez-separator bg-primary style-liner"></div>
        </div>
        <p>
          Get ready for the excitement of the Sakaja Super Cup Season 2!
          Thrilling matches will light up venues across Nairobi. This vibrant
          tournament is a game-changer in local football, dedicated to nurturing
          young talent and offering pathways for both personal and professional
          growth. It's an essential event for aspiring players in the region.
          Don't miss out on the action! ⚽️.
        </p>
      </div>
      <div class="section-content text-center">
        <div class="row">
          <div class="col-lg-6 col-md-6">
            <div class="text-left">
              <h3 class="font-weight-600 m-t10">OUR MISSION STATEMENT</h3>
              <p>
                The Sakaja Super Cup stands out with its emphasis on emerging
                talent, significant rewards, and solid organizational support.
                This secures its status as a premier competition in Nairobi.
              </p>
              <div class="row">
                <div class="col-lg-4 col-sm-4 col-4">
                  <div class="dez-media dez-img-effect off-color">
                    <img width="159" height="153" src="assets/images/sakaja7.jpg" alt="" />
                  </div>
                </div>
                <div class="col-lg-4 col-sm-4 col-4">
                  <div class="dez-media dez-img-effect off-color">
                    <img width="159" height="153" src="assets/images/sakajalogo2.jpg" alt="" />
                  </div>
                </div>
                <div class="col-lg-4 col-sm-4 col-4">
                  <div class="dez-media dez-img-effect off-color">
                    <img width="159" height="153" src="assets/images/sakaja6.jpg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 m-b30">
            <div class="dez-box">
              <div class="dez-media dez-img-effect off-color">
                <img style="width: 555px; height: 393px; object-fit: contain;" src="assets/images/67.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- About The Sports -->
  <!-- Our Sports  -->
  <div class="section-full bg-img-fix content-inner overlay-black-middle our-projects-galery" style="
      background-image: url(assets/images/background/bg5.jpg);
      background-position: center;
    ">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-sm-6 m-b30">
          <div class="border-1 p-a15 text-center text-white skew-triangle left-top hvr-wobble-horizontal">
            <div class="sports-icon">
              <img width="85" height="85" src="assets/images/sports/pic1.png" alt="" />
            </div>
            <h4 class="text-capitalize">Unforgettable Moments</h4>
            <div class="dez-separator-outer">
              <div class="dez-separator bg-primary style-liner"></div>
            </div>
            <p class="m-b0">
              Every match filled with heart-stopping excitement and incredible
            </p>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6 m-b30">
          <div class="border-1 p-a15 text-center text-white skew-triangle left-top hvr-wobble-horizontal">
            <div class="sports-icon">
              <img width="85" height="85" src="assets/images/sports/pic2.png" alt="" />
            </div>
            <h4 class="text-capitalize">Champion Medals Earned</h4>
            <div class="dez-separator-outer">
              <div class="dez-separator bg-primary style-liner"></div>
            </div>
            <p class="m-b0">
              Top teams proudly claimed medals for their outstanding
              performances.
            </p>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6 m-b30">
          <div class="border-1 p-a15 text-center text-white skew-triangle left-top hvr-wobble-horizontal">
            <div class="sports-icon">
              <img width="85" height="85" src="assets/images/sports/pic3.png" alt="" />
            </div>
            <h4 class="text-capitalize">Skilled Coaches and Staf</h4>
            <div class="dez-separator-outer">
              <div class="dez-separator bg-primary style-liner"></div>
            </div>
            <p class="m-b0">
              Guiding teams with expertise, dedication, and passion for the
              game.
            </p>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6 m-b30">
          <div class="border-1 p-a15 text-center text-white skew-triangle left-top hvr-wobble-horizontal">
            <div class="sports-icon">
              <img width="85" height="85" src="assets/images/sports/pic4.png" alt="" />
            </div>
            <h4 class="text-capitalize">Action-Packed Play Areas</h4>
            <div class="dez-separator-outer">
              <div class="dez-separator bg-primary style-liner"></div>
            </div>
            <p class="m-b0">
              Unique zones for thrilling activities, giving fans more to
              explore!
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Our Sports END -->
  <!-- Our Achievements -->
  <div class="section-full bg-white content-inner our-achievements">
    <div class="container">
      <div class="section-head text-center">
        <h2 class="h2 text-uppercase">OUR ACHIEVEMENTS</h2>
        <div class="dez-separator-outer">
          <div class="dez-separator bg-primary style-liner"></div>
        </div>
        <p>
          Season 2 of the Sakaja Super Cup launched with an electrifying start
          on Sunday, featuring 52 fiercely contested matches across the city!
          The excitement builds as the competition continues today, with teams
          battling on multiple pitches for their shot at advancing to the next
          stage.
        </p>
      </div>
      <div class="section-content text-center">
        <div class="row m-lr0">
          <div class="col-lg-3 col-sm-6 p-a0 m-b30">
            <div class="dez-box dez-media">
              <img width="292" height="292" src="assets/images/sakajalogo2.jpg" alt="" />
              <div class="dez-info-has p-a20 bg-primary text-left skew-triangle right-top text-center">
                <h4 class="text-capitalize">Record-Breaking Attendance</h4>
                <div class="dez-info-has-text">
                  Attracted the highest number of spectators in its season.
                </div>
                <div class="m-tb30"></div>
              </div>
              <div class="dez-title-bx bg-gray p-a20 text-left skew-triangle left-top">
                <h4 class="m-a0 text-capitalize">Record-Breaking Attendance</h4>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6 p-a0 m-b30">
            <div class="dez-box dez-media">
              <img width="292" height="292" src="assets/images/sakajalogo2.jpg" alt="" />
              <div class="dez-info-has p-a20 bg-primary text-left skew-triangle right-top text-center">
                <h4 class="text-capitalize">Diversity in Participation</h4>
                <div class="dez-info-has-text">
                  Teams from over 10 neighborhoods united for an inclusive
                  tournament.
                </div>
                <div class="m-tb30"></div>
              </div>
              <div class="dez-title-bx bg-gray p-a20 text-left skew-triangle left-top">
                <h4 class="m-a0 text-capitalize">Diversity in Participation</h4>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6 p-a0 m-b30">
            <div class="dez-box dez-media">
              <img width="292" height="292" src="assets/images/sakajalogo2.jpg" alt="" />
              <div class="dez-info-has p-a20 bg-primary text-left skew-triangle right-top text-center">
                <h4 class="text-capitalize">Successful Second Season</h4>
                <div class="dez-info-has-text">
                  Increased team participation by 30% compared to the first
                  season.
                </div>
                <div class="m-tb30"></div>
              </div>
              <div class="dez-title-bx bg-gray p-a20 text-left skew-triangle left-top">
                <h4 class="m-a0 text-capitalize">Successful Second Season</h4>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6 p-a0 m-b30">
            <div class="dez-box dez-media">
              <img width="292" height="292" src="assets/images/sakajalogo2.jpg" alt="" />
              <div class="dez-info-has p-a20 bg-primary text-left skew-triangle right-top text-center">
                <h4 class="text-capitalize">Community Engagement</h4>
                <div class="dez-info-has-text">
                  Hundreds of local businesses supported and sponsored the
                  event.
                </div>
                <div class="m-tb30"></div>
              </div>
              <div class="dez-title-bx bg-gray p-a20 text-left skew-triangle left-top">
                <h4 class="m-a0 text-capitalize">Community Engagement</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Team member END -->
  <!-- Sports Offered -->
  <div class="section-full bg-img-fix content-inner overlay-black-middle" style="
      background-image: url(assets/images/background/bg1.jpg);
      background-position: center;
    ">
    <div class="container">
      <div class="section-head text-center text-white">
        <h2 class="h2">CLUB GALLERY</h2>
        <div class="dez-separator-outer">
          <div class="dez-separator bg-primary style-liner"></div>
        </div>
        <p></p>
      </div>
      <div class="site-filters clearfix center m-b40">
        <ul class="filters filters1" data-bs-toggle="buttons">
          <li data-filter="" class="btn active">
            <button type="button" class="site-button white radius-xl"></button>
          </li>
        </ul>
      </div>
      <div class="clearfix">
        <ul id="masonry1" class="row dez-gallery-listing gallery-grid-4 m-b0 gallery lightgallery">
          <li class="card-container col-lg-3 col-lg-3 col-sm-6 jumping">
            <div class="dez-box dez-gallery-box">
              <div class="dez-media dez-img-overlay1 dez-img-effect zoom-slow">
                <a href="javascript:void(0);">
                  <img style="width:480; height:430; " src="assets/images/22.jpeg" alt="" />
                </a>
                <div class="overlay-bx">
                  <div class="overlay-icon">
                    <a href="#"> </a>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="office card-container col-lg-3 col-lg-3 col-sm-6 bikes jumping">
            <div class="dez-box dez-gallery-box">
              <div class="dez-media dez-img-overlay1 dez-img-effect zoom-slow dez-img-effect zoom">
                <a href="javascript:void(0);">
                  <img style="width:480; height:430;" src="assets/images/23.jpeg" alt="" />
                </a>
                <div class="overlay-bx">
                  <div class="overlay-icon">
                    <a href="#"> </a>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="card-container col-lg-3 col-lg-3 col-sm-6 jumping">
            <div class="dez-box dez-gallery-box">
              <div class="dez-media dez-img-overlay1 dez-img-effect zoom-slow">
                <a href="javascript:void(0);">
                  <img style="width:480; height:430; " src="assets/images/24.jpeg" alt="" />
                </a>
                <div class="overlay-bx">
                  <div class="overlay-icon">
                    <a href="#"> </a>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="commercial card-container col-lg-3 col-lg-3 col-sm-6 bikes">
            <div class="dez-box dez-gallery-box">
              <div class="dez-media dez-img-overlay1 dez-img-effect zoom-slow">
                <a href="javascript:void(0);">
                  <img style="width:480; height:430;" src="assets/images/25.jpeg" alt="" />
                </a>
                <div class="overlay-bx">
                  <div class="overlay-icon">
                    <a href="#"> </a>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="card-container col-lg-3 col-lg-3 col-sm-6 water bikes">
            <div class="dez-box dez-gallery-box">
              <div class="dez-media dez-img-overlay1 dez-img-effect zoom-slow">
                <a href="javascript:void(0);">
                  <img style="width:480; height:430; " src="assets/images/26.jpeg" alt="" />
                </a>
                <div class="overlay-bx">
                  <div class="overlay-icon">
                    <a href="#"> </a>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="card-container col-lg-3 col-lg-3 col-sm-6 water">
            <div class="dez-box dez-gallery-box">
              <div class="dez-media dez-img-overlay1 dez-img-effect zoom-slow">
                <a href="javascript:void(0);">
                  <img style="width:480; height:430;" src="assets/images/27.jpeg" alt="" />
                </a>
                <div class="overlay-bx">
                  <div class="overlay-icon">
                    <a href="#"> </a>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="card-container col-lg-3 col-lg-3 col-sm-6 bikes">
            <div class="dez-box dez-gallery-box">
              <div class="dez-media dez-img-overlay1 dez-img-effect zoom-slow">
                <a href="javascript:void(0);">
                  <img style="width:480; height:430;" src="assets/images/28.jpeg" alt="" />
                </a>
                <div class="overlay-bx">
                  <div class="overlay-icon">
                    <a href="#"> </a>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="card-container col-lg-3 col-lg-3 col-sm-6 water">
            <div class="dez-box dez-gallery-box">
              <div class="dez-media dez-img-overlay1 dez-img-effect zoom-slow">
                <a href="javascript:void(0);">
                  <img style="width:480; height:430; " src="assets/images/29.jpeg" alt="" />
                </a>
                <div class="overlay-bx">
                  <div class="overlay-icon">
                    <a href="#"> </a>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- Sports Offered END -->

  <!-- Why Choose Us -->
  <div class="section-full text-white bg-img-fix content-inner-1 overlay-black-middle choose-us" style="
      background-image: url(assets/images/background/bg2.jpg);
      background-position: center;
      margin-top: 40px;
    ">
    <div class="container">
      <div class="text-center text-white">
        <h2 class="h2 m-t0 m-b0 text-uppercase">
          <a href="https://www.youtube.com/watch?v=E4jFPvxWl8E" class="popup-youtube video text-white">
            Show
            <span class="text-primary">
              <i class="fa fa-play-circle-o"></i>
            </span>
            Reel
          </a>
        </h2>
      </div>
    </div>
  </div>
  <!-- Why Choose Us End -->
  <!-- Latest blog -->

  <!-- Latest blog END -->
  <!-- World Sport Template blog -->
  <div class="section-full overlay-black-dark bg-img-fix content-inner dez-move-image" style="
      background-image: url(https://citizentv.obs.af-south-1.myhuaweicloud.com/137066/conversions/GBu0rjbWYAAFPm5-og_image.webp);
    ">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 m-b30 text-white">
          <h2 class="m-t0 font-40 m-b40 text-center">
            Get Connected with our Mobile App
          </h2>
          <div class="text-center">
            <a href="javascript:void(0);" class="site-button"
              style="background-color: #fff !important; color: black !important">
              <img src="assets/images/playstore.png" alt="" srcset="" style="height: 4vh !important" />
              Download Mobile APP</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- World Sport Template END -->
  <!-- Testimoniyal blog -->
  <!-- <div class="section-full content-inner bg-white">
        <div class="container">
            <div class="section-head text-center">
                <h2 class="h2 text-uppercase">Testimonials</h2>
                <div class="dez-separator-outer ">
                    <div class="dez-separator bg-primary style-liner"></div>
                </div>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry has been the
                    industry's standard dummy text ever since the been when an unknown printer.</p>
            </div>
            <div class="section-content">
                <div class="testimonial-four owl-carousel owl-dots-none owl-theme owl-loaded">
                    <div class="item">
                        <div class="testimonial-4 text-white">
                            <div class="testimonial-pic"><img src="assets/images/testimonials/pic1.jpg" width="100"
                                    height="100" alt=""></div>
                            <div class="testimonial-text">
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry has
                                    been the standard dummy text ever since the when printer. [...]</p>
                            </div>
                            <div class="testimonial-detail"> <strong class="testimonial-name">David
                                    Matin</strong> <span class="testimonial-position">Student</span> </div>
                            <div class="quote-right"></div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="testimonial-4 text-white">
                            <div class="testimonial-pic"><img src="assets/images/testimonials/pic2.jpg" width="100"
                                    height="100" alt=""></div>
                            <div class="testimonial-text">
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry has
                                    been the standard dummy text ever since the when printer. [...]</p>
                            </div>
                            <div class="testimonial-detail"> <strong class="testimonial-name">David
                                    Matin</strong> <span class="testimonial-position">Student</span> </div>
                            <div class="quote-right"></div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="testimonial-4 text-white">
                            <div class="testimonial-pic"><img src="assets/images/testimonials/pic2.jpg" width="100"
                                    height="100" alt=""></div>
                            <div class="testimonial-text">
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry has
                                    been the standard dummy text ever since the when printer. [...]</p>
                            </div>
                            <div class="testimonial-detail"> <strong class="testimonial-name">David
                                    Matin</strong> <span class="testimonial-position">Student</span> </div>
                            <div class="quote-right"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
  <!-- Testimoniyal END -->
</div>
<!-- Content END-->
