import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CoreService {
  baseUrl: string = environment.baseUrl;
  constructor(private http: HttpClient) {}

  getAllMatches(finaldata: any) {
    return this.http.get<any[]>(
      `${this.baseUrl}client/matches/main/?${finaldata}`,
      {}
    );
  }
  getAllValidDates() {
    return this.http.get<any[]>(`${this.baseUrl}client/api/match-dates/`, {});
  }
  getRandomMatches() {
    return this.http.get<any[]>(`${this.baseUrl}client/getRandomSKMatch/`, {});
  }
}
