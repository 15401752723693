<!-- header -->
<header class="site-header header-style-6 dark mo-left">
  <!-- main header -->
  <div
    class="sticky-header is-fixed main-bar-wraper navbar-expand-lg navbar-expand-lg"
  >
    <div class="main-bar clearfix">
      <div class="header-bar">
        <div class="container clearfix">
          <!-- website logo -->
          <div class="logo-header logo-color mostion logo-color">
            <a href="index.html"
              ><img
                src="assets/images/sakajalogo.png"
                width="193"
                height="89"
                alt=""
            /></a>
          </div>
          <!-- nav toggle button -->
          <button
            class="navbar-toggler collapsed navicon justify-content-end"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
          <!-- extra nav -->
          <div class="extra-nav">
            <div class="extra-cell">
              <!--  <button
                id="quik-search-btn"
                type="button"
                class="site-button radius-xl"
              >
                <i class="fa fa-search"></i>
              </button>-->
            </div>
          </div>
          <!-- Quik search -->
          <!--       <div class="dez-quik-search bg-primary-dark">
            <form action="index.html#">
              <input
                name="search"
                value=""
                type="text"
                class="form-control"
                placeholder="Type to  (fixtures)"
              />
              <span id="quik-search-remove"><i class="fa fa-remove"></i></span>
            </form>
          </div>-->
          <!-- main nav -->
          <div
            class="header-nav navbar-collapse collapse justify-content-end"
            id="navbarNavDropdown"
          >
            <ul class="nav navbar-nav">
              <li [class.active]="activeSection === 'home'">
                <a
                  href="javascript:;"
                  (click)="scrollTo('home'); setActive('home')"
                  >Home<i class="fa fa-chevron-down"></i
                ></a>
              </li>
              <li [class.active]="activeSection === 'sectionFixtures'">
                <a
                  href="javascript:;"
                  (click)="
                    scrollTo('sectionFixtures'); setActive('sectionFixtures')
                  "
                  >Fixtures<i class="fa fa-chevron-down"></i
                ></a>
              </li>

              <li [class.active]="activeSection === 'next-match'">
                <a
                  href="javascript:;"
                  (click)="scrollTo('next-match'); setActive('next-match')"
                  >Next Match<i class="fa fa-chevron-down"></i
                ></a>
              </li>

              <li
                class="has-mega-menu"
                [class.active]="activeSection === 'sectionAbout'"
              >
                <a
                  href="javascript:;"
                  (click)="scrollTo('sectionAbout'); setActive('sectionAbout')"
                  >About Match<i class="fa fa-chevron-down"></i
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- main header END -->
</header>
<!-- header END -->
