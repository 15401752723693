import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { UpcomingMatchCardComponent } from './match-cards/upcoming-match-card/upcoming-match-card.component';
import { CompletedMatchCardComponent } from './match-cards/completed-match-card/completed-match-card.component';
import { LiveMatchCardComponent } from './match-cards/live-match-card/live-match-card.component';
import { CarouselModule } from 'primeng/carousel';
import { CoreService } from '../../services/core.service';
import {
  BsDatepickerConfig,
  BsDatepickerModule,
  BsDatepickerViewMode,
} from 'ngx-bootstrap/datepicker';
import { CalendarModule } from 'primeng/calendar';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { SharedModule } from '../../shared.module';
import moment from 'moment';
import { SearchFixturesComponent } from '../shared/header/search-fixtures/search-fixtures.component';
import dayjs from 'dayjs';

@Component({
  selector: 'app-fixtures',
  standalone: true,
  imports: [
    RouterModule,
    FormsModule,
    CommonModule,
    LiveMatchCardComponent,
    CompletedMatchCardComponent,
    UpcomingMatchCardComponent,
    CarouselModule,
    CalendarModule,
    BsDatepickerModule,
    SharedModule,
    SearchFixturesComponent,
  ],
  templateUrl: './fixtures.component.html',
  styleUrl: './fixtures.css',
})
export class FixturespageComponent implements OnInit, AfterViewInit {
  datePickerValue: Date = new Date(2020, 7);
  dateRangePickerValue?: (Date | undefined)[];
  range1: Date = new Date(2020, 5);
  range2: Date = new Date(2020, 8);
  minMode: BsDatepickerViewMode = 'month';

  bsConfig?: Partial<BsDatepickerConfig>;

  rangeDates: Date[] | undefined;

  dateRange: any = [
    {
      selected: false,
      dateformatted: '2024-10-18',
    },
    {
      selected: false,
      dateformatted: '2024-10-19',
    },
    {
      selected: false,
      dateformatted: '2024-10-20',
    },
    {
      selected: false,
      dateformatted: '2024-10-21',
    },
    {
      selected: false,
      dateformatted: '2024-10-22',
    },
    {
      selected: false,
      dateformatted: '2024-10-23',
    },
    {
      selected: false,
      dateformatted: '2024-10-24',
    },
    {
      selected: false,
      dateformatted: '2024-10-25',
    },
    {
      selected: false,
      dateformatted: '2024-10-26',
    },
    {
      selected: false,
      dateformatted: '2024-10-27',
    },
    {
      selected: false,
      dateformatted: '2024-10-28',
    },
    {
      selected: false,
      dateformatted: '2024-10-29',
    },
    {
      selected: false,
      dateformatted: '2024-10-30',
    },
    {
      selected: false,
      dateformatted: '2024-10-31',
    },
    {
      selected: false,
      dateformatted: '2024-11-01',
    },
    {
      selected: false,
      dateformatted: '2024-11-02',
    },
    {
      selected: false,
      dateformatted: '2024-11-03',
    },
    {
      selected: false,
      dateformatted: '2024-11-04',
    },
    {
      selected: false,
      dateformatted: '2024-11-05',
    },
    {
      selected: false,
      dateformatted: '2024-11-06',
    },
    {
      selected: false,
      dateformatted: '2024-11-07',
    },
    {
      selected: false,
      dateformatted: '2024-11-08',
    },
    {
      selected: false,
      dateformatted: '2024-11-09',
    },
    {
      selected: false,
      dateformatted: '2024-11-10',
    },
    {
      selected: false,
      dateformatted: '2024-11-11',
    },
    {
      selected: false,
      dateformatted: '2024-11-12',
    },
    {
      selected: false,
      dateformatted: '2024-11-13',
    },
    {
      selected: false,
      dateformatted: '2024-11-14',
    },
    {
      selected: false,
      dateformatted: '2024-11-15',
    },
    {
      selected: false,
      dateformatted: '2024-11-16',
    },
    {
      selected: false,
      dateformatted: '2024-11-17',
    },
    {
      selected: false,
      dateformatted: '2024-11-18',
    },
    {
      selected: false,
      dateformatted: '2024-11-19',
    },
    {
      selected: false,
      dateformatted: '2024-11-20',
    },
    {
      selected: false,
      dateformatted: '2024-11-21',
    },
    {
      selected: false,
      dateformatted: '2024-11-22',
    },
    {
      selected: false,
      dateformatted: '2024-11-23',
    },
    {
      selected: false,
      dateformatted: '2024-11-24',
    },
    {
      selected: false,
      dateformatted: '2024-11-25',
    },
    {
      selected: false,
      dateformatted: '2024-11-26',
    },
    {
      selected: false,
      dateformatted: '2024-11-27',
    },
    {
      selected: false,
      dateformatted: '2024-11-28',
    },
    {
      selected: false,
      dateformatted: '2024-11-29',
    },
    {
      selected: false,
      dateformatted: '2024-11-30',
    },
  ];
  selectedDate: any;
  datesLoop = {};
  allMatches: any = [];
  latestMatchId = null;
  defaultDateRange: Date[] = [
    moment().subtract(100, 'days').toDate(), // 7 days ago
    moment('2024-12-30').toDate(), // November 30, 2024
  ];
  bsconfig: Partial<BsDatepickerConfig> = {
    rangeInputFormat: 'MMMM Do YYYY, h:mm:ss a',
    dateInputFormat: 'MMMM Do YYYY, h:mm:ss a',
    showWeekNumbers: false,
    containerClass: 'theme-orange',
    isAnimated: true,
    datesEnabled: [],
  };
  todayDate = new Date();

  ngOnInit() {
    this.dateRangePickerValue = [this.range1, this.range2];
    this.bsConfig = Object.assign(
      {},
      {
        minMode: this.minMode,
      }
    );

    let dt = {
      selected: true,
      // format to this kindof format 2024-10-17',auto pick todays date
      dateformatted: '2024-10-26',
    };

    console.log(dt);

    this.setSelected(this.defaultDateRange);
    this.coreService.getAllValidDates().subscribe((res: any[]) => {
      console.log(res);
      this.bsconfig.datesEnabled = res.map((x) => new Date(x.date));
    });
  }
  selected: any;

  isInvalidDate(date: any) {
    return date.weekday() === 0;
  }
  constructor(
    private coreService: CoreService,
    private cdr: ChangeDetectorRef
  ) {}

  choosedDate(date: any) {
    console.log(date);
  }
  ls() {
    // FUNCTION TO GENERATE ALL DATES DURING CERTAIN RANGE DATES
    // const startDate = new Date('2024-10-17');
    // const endDate = new Date('2024-11-30');
    // for (
    //   let date = startDate;
    //   date <= endDate;
    //   date.setDate(date.getDate() + 1)
    // ) {
    //   this.dateRange.push({
    //     selected: false,
    //     dateformatted: date.toISOString().split('T')[0],
    //   });
    // }
  }
  setSelected(dateList: any) {
    this.onValueChange(dateList);

    this.cdr.detectChanges();
  }

  getFilteredMatches(dateList: any) {
    // date_from
    // dateTo
    let finalData = {
      dateFrom: dateList[0],
      dateTo: dateList[1],
    };
    let queryString = '';

    // Check if each field in finalData has a value, and append it to the query string if it does

    if (finalData.dateFrom) {
      // If this is not the first parameter, add an '&' first
      if (queryString) {
        queryString += '&';
      }
      queryString += `dateFrom=${finalData.dateFrom}`;
    }

    if (finalData.dateTo) {
      // If this is not the first parameter, add an '&' first
      if (queryString) {
        queryString += '&';
      }
      queryString += `dateTo=${finalData.dateTo}`;
    }
    this.coreService.getAllMatches(queryString).subscribe((data: any) => {
      console.log(data);
      data.forEach((element: any) => {
        element.matches.sort((a: any, b: any) => {
          const dateA = new Date(a.date_time).getTime();
          const dateB = new Date(b.date_time).getTime();
          return dateB - dateA; // Sort in ascending order
        });
      });

      this.allMatches = data;
      this.findLAtestMAtch();
    });
  }

  onValueChangeForm(event: any) {
    // Convert to ISO 8601 format
    console.log(event);

    const isoString1 = event.toISOString();
    const isoString2 = event.toISOString();
    let xp = [isoString1, isoString2];
    this.getFilteredMatches(xp);
  }
  onValueChange(event: any) {
    console.log(event);
    const date1 = new Date(event[0]);
    const date2 = new Date(event[1]);

    // Convert to ISO 8601 format
    const isoString1 = date1.toISOString();
    const isoString2 = date2.toISOString();
    let xp = [isoString1, isoString2];
    this.getFilteredMatches(xp);
  }
  findLAtestMAtch() {
    if (this.allMatches && this.allMatches.length > 0) {
      // Flatten all match dates into one array
      const matches = this.allMatches.flatMap((day: any) => day.matches);

      // Sort matches by date in descending order
      matches.sort(
        (a: any, b: any) =>
          new Date(b.date_time).getTime() - new Date(a.date_time).getTime()
      );

      // Get the latest match ID
      this.latestMatchId = matches[0]?.xid;
    }
  }
  searchByName($event: any) {
    console.log('!!!!');
    console.log($event.target.value);

    let finalData = {
      team_name: $event.target.value,
      dateFrom: '',
      dateTo: '',
    };

    // Initialize an empty string for the query parameters
    let queryString = '';

    // Check if each field in finalData has a value, and append it to the query string if it does
    if (finalData.team_name) {
      queryString += `team_name=${finalData.team_name}`;
    }

    if (finalData.dateFrom) {
      // If this is not the first parameter, add an '&' first
      if (queryString) {
        queryString += '&';
      }
      queryString += `dateFrom=${finalData.dateFrom}`;
    }

    if (finalData.dateTo) {
      // If this is not the first parameter, add an '&' first
      if (queryString) {
        queryString += '&';
      }
      queryString += `dateTo=${finalData.dateTo}`;
    }

    this.coreService.getAllMatches(queryString).subscribe((data: any) => {
      console.log(data);
      data.forEach((element: any) => {
        element.matches.sort((a: any, b: any) => {
          const dateA = new Date(a.date_time).getTime();
          const dateB = new Date(b.date_time).getTime();
          return dateB - dateA; // Sort in ascending order
        });
      });

      this.allMatches = data;
      this.findLAtestMAtch();
    });
  }
  ngAfterViewInit(): void {
    this.highlightEnabledDates();
  }

  highlightEnabledDates(): void {
    const dateCells = document.querySelectorAll('.bs-datepicker .day');
    console.log('Date Cells:', dateCells); // Debugging: Log the selected date cells

    const enabledDates = this.bsconfig.datesEnabled?.map((date) =>
      dayjs(date).format('YYYY-MM-DD')
    );
    console.log('Enabled Dates:', enabledDates); // Debugging: Log the enabled dates array

    dateCells.forEach((cell) => {
      // Check if the cell has the 'ng-star-inserted' class (indicating a disabled date)
      const isDisabled = cell.classList.contains('ng-star-inserted');
      const cellDate = dayjs(cell.getAttribute('aria-label')).format(
        'YYYY-MM-DD'
      );
      console.log('Cell Date:', cellDate); // Debugging: Log each cell's date

      // Only highlight if the date is enabled and the cell is not disabled
      if (!isDisabled && enabledDates?.includes(cellDate)) {
        cell.classList.add('highlight-enabled-date');
      }
    });
  }
}
